// frontend/src/components/Orders.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getClientsOrders, getClientsName } from '../services/api'; 
import Layout from './Layout';
import moment from 'moment';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [clients, setClients] = useState([]); 
    const [client, setClient] = useState('ALL');
    const [status, setStatus] = useState('ALL');
    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
    const [useCutoff, setUseCutoff] = useState(false); 
    const fetchClients = async () => {
        const response = await getClientsName(); 
        if (response.success) {
            setClients(response.clients);
        }
    };

    // Fetch clients on component mount
    useEffect(() => {
        fetchClients();
    }, []);


    const loadOrders = async () => {
        const filters = { client, status, dateFrom, dateTo, useCutoff }; 
        const response = await getClientsOrders(filters);
        if (response.success) {
            setOrders(response.orders);
        }
    };

    const handleSearch = () => {
        loadOrders();
    };

    // Validation to ensure dateFrom is not greater than dateTo
    const handleDateFromChange = (e) => {
        const selectedFromDate = e.target.value;
        if (moment(selectedFromDate).isAfter(dateTo)) {
            alert("From Date cannot be greater than To Date");
            return; // Do not update the state
        }
        setDateFrom(selectedFromDate);
    };

    const handleDateToChange = (e) => {
        const selectedToDate = e.target.value;
        if (moment(selectedToDate).isBefore(dateFrom)) {
            alert("To Date cannot be earlier than From Date");
            return; // Do not update the state
        }
        setDateTo(selectedToDate);
    };

    // Disable status and date range when "Required Dispatched Today Orders" is checked
    const handleCutoffChange = (e) => {
        setUseCutoff(e.target.checked);
        if (e.target.checked) {
            setStatus('ALL'); // Reset status to 'ALL' when the checkbox is checked
        }
    };

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Box sx={{ mb: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>Client's Orders</Typography>
                </Box>
                {/* Filters */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                        <TextField
                            id="client"
                            label="Client"
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                            fullWidth
                            select
                        >
                            <MenuItem value="ALL">ALL</MenuItem>
                            {clients.map((client) => (
                                <MenuItem key={client.clientName} value={client.clientName}>
                                    {client.clientName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                        <TextField
                            id="status"
                            label="Status"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            fullWidth
                            select
                            disabled={useCutoff} // Disable when "Required Dispatched Today Orders" is checked
                        >
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="New">New</MenuItem>
                            <MenuItem value="Dispatched">Dispatched</MenuItem>
                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                        <TextField
                            id="from-date"
                            label="From Date"
                            type="date"
                            value={dateFrom}
                            onChange={handleDateFromChange} // Apply validation logic here
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={useCutoff} // Disable date range when cutoff is checked
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                        <TextField
                            id="to-date"
                            label="To Date"
                            type="date"
                            value={dateTo}
                            onChange={handleDateToChange} // Apply validation logic here
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={useCutoff} // Disable date range when cutoff is checked
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {/* Add Checkbox for Required Dispatched Today Orders */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={useCutoff}
                                    onChange={handleCutoffChange} // Apply status disabling logic here
                                />
                            }
                            label="Required Dispatch Today"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" onClick={handleSearch}>Search</Button>
                    </Grid>
                </Grid>

                {/* Data Grid */}
                <Box sx={{ height: 600, width: '100%', marginTop: 3 }}>
                    <DataGrid
                        rows={orders}
                        columns={[
                            { field: 'external_ref', headerName: 'Order Number', width: 140 },
                            { field: 'clientName', headerName: 'Client Name', width: 150 },
                            { field: 'orderStatus', headerName: 'Status', width: 120 },
                            { field: 'createdAt', headerName: 'Created At', width: 200 },
                            { field: 'fulfilmentChannel', headerName: 'Channel', width: 100 },
                            { field: 'shipping_carrier', headerName: 'Shipping Carrier', width: 130 },
                            { field: 'shipping_method', headerName: 'Shipping Method', width: 200 },
                            { field: 'tracking', headerName: 'Tracking Number', width: 200 },
                            { field: 'dispatchedDate', headerName: 'Dispatched Date', width: 200 }
                        ]}
                        slots={{ toolbar: GridToolbar }} 
                        getRowId={(row) => row.orderId}  
                    />
                </Box>
            </Box>
        </Layout>
    );
};

export default Orders;
