// frontend/src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    allVariants: {
      color: '#333', // Global text color
    },
  },
  palette: {
    primary: {
      main: '#1976d2', // Your desired primary color
      dark: '#115293', // Darker shade for hover effect
    },
    text: {
      primary: '#333', // Set primary text color globally
    },
    background: {
      default: '#f4f6f8', // Set the default background color if needed
      paper: '#fff', // Set the background color for paper components
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: '#333', // Text color for the entire body
          fontFamily: 'Roboto, sans-serif', // Global font family for the body
        },
      },
    },
  },
});

export default theme;
