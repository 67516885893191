// frontend/src/components/BagOrders.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Divider,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    Toolbar,
    Tooltip,
    TableSortLabel,
    TablePagination,
    IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { alpha } from '@mui/material/styles';
import { getOrders, addOrder, removeOrder } from '../services/api';
import { validateEAN13, validatePAN, validateResellerRefID } from '../utils/validation';
import Layout from './Layout';
import { useSnackbar } from '../contexts/SnackbarContext'; // Import the useSnackbar hook

const BagOrders = () => {
    const { showSnackbar } = useSnackbar(); // Use the global Snackbar context
    const [orders, setOrders] = useState([]);
    const [orderToDelete, setOrderToDelete] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('ean13');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dateTime, setDateTime] = useState('');

    // New state for form validation
    const [ean13Error, setEAN13Error] = useState('');
    const [panError, setPanError] = useState('');
    const [resellerRefError, setResellerRefError] = useState('');

    // New state for the instruction dialog
    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);

    useEffect(() => {
        const updateDateTime = () => {
            const today = new Date();
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            setDateTime(today.toLocaleDateString('en-UK', options));
        };

        updateDateTime(); // Initialize the date and time on load
        const intervalId = setInterval(updateDateTime, 1000); // Update every second
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    const loadOrders = async () => {
        try {
            const response = await getOrders();
            if (response.success) {
                setOrders(response.orders);
            } else {
                showSnackbar('Error loading orders.', 'error');
            }
        } catch (error) {
            showSnackbar('Error loading orders.', 'error');
        }
    };

    useEffect(() => {
        loadOrders();
        
    }, []);

    // Handle refresh click
    const handleRefresh = () => {
        loadOrders();  // Reload orders from backend
    };

    const handleAddOrder = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const order = {
            ean13: formData.get('ean13'),
            pan: formData.get('pan'),
            reseller_ref: formData.get('reseller_ref')
        };

        // Validate before submission
        if (!validateEAN13(order.ean13)) {
            setEAN13Error('EAN13 must start with "5"');
            return;
        } else {
            setEAN13Error('');
        }

        if (!validatePAN(order.pan)) {
            setPanError('PAN must start with 6 or 7 and cannot contain spaces.');
            return;
        } else {
            setPanError('');
        }

        if (!validateResellerRefID(order.reseller_ref)) {
            setResellerRefError('ResellerRefNo must start with 11 alphanumeric characters followed by a hyphen and cannot contain spaces.');
            return;
        } else {
            setResellerRefError('');
        }

        try {
            const response = await addOrder(order);
            if (response.success) {
                setOrders(response.orders);
                showSnackbar(response.alert_message, 'success');
                event.target.reset();
                document.getElementById('ean13').focus();
            } else {
                showSnackbar(response.alert_message, 'error');
            }
        } catch (error) {
            showSnackbar('Error adding order.', 'error');
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedOrders = orders.slice().sort((a, b) => {
        if (orderBy === 'ean13') {
            return order === 'asc' ? a.EAN13.localeCompare(b.EAN13) : b.EAN13.localeCompare(a.EAN13);
        } else if (orderBy === 'pan') {
            return order === 'asc' ? a.PAN.localeCompare(b.PAN) : b.PAN.localeCompare(a.PAN);
        }
        return 0;
    });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orders.map((order) => order.Id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleRemoveOrder = () => {
        setOrderToDelete(selected);
        setOpenDialog(true);
    };

    const confirmDeleteOrder = async () => {
        setOpenDialog(false);
        if (selected.length > 0) {
            try {
                const response = await removeOrder(selected); // Pass the array of selected IDs
                if (response.success) {
                    setOrders(orders.filter(order => !selected.includes(order.Id))); // Remove deleted orders from the UI
                    showSnackbar(response.alert_message, 'success');
                    setSelected([]);
                } else {
                    showSnackbar(response.alert_message, 'error');
                }
            } catch (error) {
                showSnackbar('Error removing order.', 'error');
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Buy A Gift Report
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {dateTime}
                    </Typography>
                </Box>
                
                {/* Add New Order Form */}
                <Grid container spacing={2} sx={{ marginTop: 3 }}>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{ padding: 3, height: 'auto' }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6" gutterBottom>
                                    Add New Order
                                </Typography>
                                <Tooltip title="Show Instructions">
                                    <IconButton
                                        color="primary"
                                        onClick={() => setOpenInstructionsDialog(true)} // Open the dialog when clicked
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <form id="barcodeForm" onSubmit={handleAddOrder}>
                                <TextField
                                    label="EAN13 Barcode"
                                    id="ean13"
                                    name="ean13"
                                    fullWidth
                                    onChange={(e) => setEAN13Error(validateEAN13(e.target.value) ? '' : 'EAN13 must start with "5"')}
                                    error={!!ean13Error}
                                    helperText={ean13Error}
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    label="PAN Barcode"
                                    name="pan"
                                    fullWidth
                                    onChange={(e) => setPanError(validatePAN(e.target.value) ? '' : 'PAN must start with 6 or 7 and cannot contain spaces.')}
                                    error={!!panError}
                                    helperText={panError}
                                    sx={{ marginBottom: 2 }}
                                    required
                                />
                                <TextField
                                    label="ResellerRefNo"
                                    name="reseller_ref"
                                    fullWidth
                                    onChange={(e) => setResellerRefError(validateResellerRefID(e.target.value) ? '' : 'ResellerRefNo must start with 11 alphanumeric characters followed by a hyphen.')}
                                    error={!!resellerRefError}
                                    helperText={resellerRefError}
                                    required
                                    sx={{ marginBottom: 2 }}
                                />
                                <Button type="submit" variant="contained" fullWidth>
                                    Add Order
                                </Button>
                            </form>
                        </Paper>
                    </Grid>

                    {/* Orders Table */}
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <Toolbar
                                sx={[{
                                    pl: { sm: 2 },
                                    pr: { xs: 1, sm: 1 },
                                }, selected.length > 0 && {
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                }]}
                            >
                                {selected.length > 0 ? (
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        color="inherit"
                                        variant="subtitle1"
                                        component="div"
                                    >
                                        {selected.length} selected
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        Orders
                                    </Typography>
                                )}

                                {selected.length > 0 ? (
                                    <Tooltip title="Delete">
                                        <IconButton onClick={handleRemoveOrder}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Refresh list">
                                        <IconButton onClick={handleRefresh}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Toolbar>
                            <TableContainer>
                                <Table sx={{ minWidth: 750 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    indeterminate={selected.length > 0 && selected.length < orders.length}
                                                    checked={orders.length > 0 && selected.length === orders.length}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{ 'aria-label': 'select all orders' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'ean13'}
                                                    direction={orderBy === 'ean13' ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, 'ean13')}
                                                >
                                                    EAN13
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'pan'}
                                                    direction={orderBy === 'pan' ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, 'pan')}
                                                >
                                                    PAN
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>Transaction Time Stamp</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Store ID</TableCell>
                                            <TableCell>Retailer ID</TableCell>
                                            <TableCell>ResellerRefNo</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedOrders
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((order, index) => {
                                                const isItemSelected = isSelected(order.Id);
                                                const labelId = `enhanced-table-checkbox-${order.Id}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, order.Id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={order.Id}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                                            {order.EAN13}
                                                        </TableCell>
                                                        <TableCell>{order.PAN}</TableCell>
                                                        <TableCell>{order['Transaction Time Stamp']}</TableCell>  {/* Accessing with bracket notation */}
                                                        <TableCell>{order.Value}</TableCell>
                                                        <TableCell>{order['Store ID']}</TableCell>
                                                        <TableCell>{order['Retailer ID']}</TableCell>
                                                        <TableCell>{order.ResellerRefNo}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={orders.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                {/* Delete Confirmation Dialog */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you really want to delete these orders? This process cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={confirmDeleteOrder} color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Instructions Dialog */}
                <Dialog open={openInstructionsDialog} onClose={() => setOpenInstructionsDialog(false)}>
                    <DialogTitle>Daily Operation Instructions</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        <Typography variant="body1">
                                1. Reload the page before starting by clicking on the company logo or "Refresh List" button.
                            </Typography>
                            <Typography variant="body1">
                                2. Scan the EAN13, PAN barcode, and order number (found on the MP packing slip) into the system.
                            </Typography>
                            <Typography variant="body1">
                                3. No need to press "Export to CSV" as the process is fully automated.
                            </Typography>
                            <Typography variant="body1">
                                4. The CSV file is automatically exported and sent to Moonpig at 22:00 every night.
                            </Typography>
                            <Divider sx={{ my: 2 }} />

                            {/* Warning/Attention Box */}
                            <Paper 
                                elevation={2} 
                                sx={{ 
                                    padding: 2, 
                                    backgroundColor: '#ffe6e6', // Light red background
                                    borderLeft: '6px solid #ff6347', // Orange/red accent border
                                    marginTop: 2 
                                }}
                            >
                                <Typography variant="h6" sx={{ color: '#ff6347', fontWeight: 'bold' }}>
                                    Attention
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1 }}>
                                    i. <strong>DO NOT USE</strong> the old Excel input procedure anymore.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1 }}>
                                    ii. <strong>No spaces</strong> are allowed in any input fields.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1 }}>
                                    iii. After adding an order, <strong>confirm whether the total number of orders is correct</strong>.
                                </Typography>
                            </Paper>


                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenInstructionsDialog(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );
};

export default BagOrders;
