// components/FulfillmentReport.js

import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Grid,
} from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
} from 'recharts';
import { MaterialReactTable } from 'material-react-table';
import { getFulfillmentCounts } from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';

const fulfillmentCategories = ['0 Days', '1 Day', '2 Days', '3 Days', '4+ Days'];
const colors = ['#197DC2', '#20c997', '#dc3545', '#ffc107', '#6f42c1', '#007bff']; // Add more colors as needed

const FulfillmentReport = ({ granularity, startDate, endDate }) => {
    const [reportData, setReportData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { showSnackbar } = useSnackbar();

    const fetchReportData = async () => {
        setLoading(true);
        try {
            const data = await getFulfillmentCounts(granularity, startDate, endDate);

            // Process data to aggregate fulfillment categories per client and include AvgFulfillmentTime
            const clientMap = {};

            data.forEach(item => {
                const client = item.clientName;
                if (!clientMap[client]) {
                    clientMap[client] = {
                        clientName: client,
                        '0 Days': 0,
                        '1 Day': 0,
                        '2 Days': 0,
                        '3 Days': 0,
                        '4+ Days': 0,
                        AvgFulfillmentTime: item.AvgFulfillmentTime,
                    };
                }
                clientMap[client][item.FulfillmentCategory] = item.OrderCount;
            });

            const processedData = Object.values(clientMap);

            setReportData(processedData);
            showSnackbar('Fulfillment report data fetched successfully.', 'success');
        } catch (error) {
            showSnackbar('Failed to fetch fulfillment report data.', 'error');
            console.error('Fetch Fulfillment Report Data Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReportData();
    }, [granularity, startDate, endDate]);

    // Since data is already aggregated, use it directly
    const processedData = reportData;

    // Define columns for MaterialReactTable
    const columns = React.useMemo(() => [
        {
            accessorKey: 'clientName',
            header: 'Client Name',
            muiTableHeadCellProps: { style: { color: '#333' } },
            enableHiding: false,
        },
        ...fulfillmentCategories.map(category => ({
            accessorKey: category,
            header: category,
            muiTableHeadCellProps: { style: { color: '#333' } },
        })),
        {
            accessorKey: 'AvgFulfillmentTime',
            header: 'Avg Fulfillment Time (Days)',
            muiTableHeadCellProps: { style: { color: '#333' } },
            Cell: ({ cell }) => cell.getValue()?.toFixed(2),
        },
    ], []);

    return (
        <Box sx={{ mb: 5 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                Fulfillment Time Report
            </Typography>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {/* Charts */}
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Fulfillment Counts per Client
                            </Typography>
                            <Box sx={{ width: '100%', height: 400 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        data={processedData}
                                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="clientName" />
                                        <YAxis allowDecimals={false} />
                                        <Tooltip />
                                        <Legend />
                                        {fulfillmentCategories.map((category, index) => (
                                            <Bar
                                                key={category}
                                                dataKey={category}
                                                stackId="a"
                                                fill={colors[index % colors.length]}
                                            />
                                        ))}
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Average Fulfillment Time per Client
                            </Typography>
                            <Box sx={{ width: '100%', height: 400 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={processedData}
                                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="clientName" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            type="monotone"
                                            dataKey="AvgFulfillmentTime"
                                            stroke="#6f42c1"
                                            activeDot={{ r: 8 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* MaterialReactTable */}
                    <Box sx={{ mt: 3 }}>
                        <MaterialReactTable
                            columns={columns}
                            data={processedData}
                            initialState={{
                                showColumnFilters: false,
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    backgroundColor: '#fff',
                                    '&:nth-of-type(even)': {
                                        backgroundColor: '#fff',
                                    },
                                },
                            }}
                            muiTableBodyRowProps={{
                                sx: {
                                    backgroundColor: '#fff',
                                    '&:nth-of-type(even)': {
                                        backgroundColor: '#fff',
                                    },
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    padding: '8px',
                                    backgroundColor: '#fff',
                                },
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default FulfillmentReport;
