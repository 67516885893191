import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const OverallSummary = ({ summaryData, granularity, chartData }) => {
    return (
        <Box>
            {/* Overall Summary Content */}
            <Box sx={{ mb: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                    Orders Overview
                </Typography>
                {chartData.length > 0 ? (
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={chartData}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {/* Stacked Bars */}
                            <Bar dataKey="DispatchedOrders" stackId="a" fill="#20c997" />
                            <Bar dataKey="CancelledOrders" stackId="a" fill="#dc3545" />
                            <Bar dataKey="RemainingInProduction" stackId="a" fill="#197DC2" />
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No data available to display the chart.
                    </Typography>
                )}
            </Box>

            {/* Overall Summary Table Section */}
            <Box sx={{ mb: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                    Overall Summary
                </Typography>
                {summaryData.length > 0 ? (
                    <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Period</strong></TableCell>
                                    <TableCell align="right"><strong>Received Orders</strong></TableCell>
                                    <TableCell align="right"><strong>Dispatched Orders</strong></TableCell>
                                    <TableCell align="right"><strong>Cancelled Orders</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {summaryData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {granularity === 'Day'
                                                ? moment(row.date).format('YYYY-MM-DD')
                                                : granularity === 'Week'
                                                    ? `Week Starting ${moment(row.weekStartDate).format('YYYY-MM-DD')}`
                                                    : moment({ year: row.Year, month: row.month - 1 }).format('YYYY MMMM')}
                                        </TableCell>
                                        <TableCell align="right">{row.receivedOrders}</TableCell>
                                        <TableCell align="right">{row.dispatchedOrders}</TableCell>
                                        <TableCell align="right">{row.cancelledOrders}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No summary data available for the selected criteria.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default OverallSummary;
