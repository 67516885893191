// frontend/src/components/Navigation.js
import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box } from '@mui/material';
import { Menu as MenuIcon, AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';

const Navigation = ({ handleDrawerToggle }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { logout, user, } = useContext(AuthContext); // Access user from AuthContext
    const handleLogout = async () => {
        try {
            await axios.post('/api/auth/logout');
            sessionStorage.removeItem('token');
            logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const handleProfileClick = () => {
        navigate('/profile');
    };


    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed" sx={{ zIndex: 1200 }}> {/* Set zIndex to 1200 */}
            <Toolbar>
                {/* Menu button for mobile screens */}
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 1, color: 'white' }}>
                    IV Creative OMS
                </Typography>

                {/* Display the username next to the profile icon */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {user && (
                        <Typography variant="body1" sx={{ color: 'white', marginRight: '8px', mb:2 }}>
                            Hi, {user.FirstName} {user.LastName}!  {/* Note the correct capitalization */}
                        </Typography>
                    )}
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="account"
                        onClick={handleMenuOpen}
                    >
                        <AccountCircle />
                    </IconButton>
                </Box>
                
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleProfileClick}>My Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;
