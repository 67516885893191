// frontend/src/components/History.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Layout from './Layout';
import { getHistory } from '../services/api';

const History = () => {
    const [orders, setOrders] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ean13, setEan13] = useState('');
    const [pan, setPan] = useState('');
    const [resellerRef, setResellerRef] = useState('');
    const [error, setError] = useState(null);
    const [dateTime, setDateTime] = useState('');

    useEffect(() => {
        const updateDateTime = () => {
            const today = new Date();
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            setDateTime(today.toLocaleDateString('en-US', options));
        };

        updateDateTime(); // Initialize the date and time on load
        const intervalId = setInterval(updateDateTime, 1000); // Update every second
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    const loadHistory = async () => {
        const params = { startDate, endDate, ean13, pan, resellerRef };
        try {
            const response = await getHistory(params);
            setOrders(response.orders);
            setError(response.error);
        } catch (err) {
            console.error('Error fetching history:', err);
            setError('Error fetching history data.');
        }
    };

    const handleSearch = () => {
        loadHistory();
    };

    const columns = [
        { field: 'EAN13', headerName: 'EAN13', flex: 1 },
        { field: 'PAN', headerName: 'PAN', flex: 1 },
        { field: 'Transaction Time Stamp', headerName: 'Transaction Time Stamp', flex: 1 },
        { field: 'Value', headerName: 'Value', flex: 1 },
        { field: 'Store ID', headerName: 'Store ID', flex: 1 },
        { field: 'Retailer ID', headerName: 'Retailer ID', flex: 1 },
        { field: 'ResellerRefNo', headerName: 'ResellerRefNo', flex: 1 },
        { field: 'CreatedDate', headerName: 'Created Date', flex: 1 },
        { field: 'ExportedCSV', headerName: 'Exported CSV', flex: 1 },
    ];


    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Buy A Gift Report - Entries History
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    {dateTime}
                </Typography>

                {/* Search Filters */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="From"
                            type="date"
                            fullWidth
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="To"
                            type="date"
                            fullWidth
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="EAN13"
                            fullWidth
                            value={ean13}
                            onChange={(e) => setEan13(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="PAN"
                            fullWidth
                            value={pan}
                            onChange={(e) => setPan(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="Reseller Ref No."
                            fullWidth
                            value={resellerRef}
                            onChange={(e) => setResellerRef(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ padding: '6px 16px' }}
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>

                {error && (
                    <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
                        {error}
                    </Typography>
                )}

                {/* DataGrid for History Table */}
                <Paper sx={{ marginTop: 4 }}>
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={orders}
                            columns={columns}
                            getRowId={(row) => row.EAN13 + row.PAN} // Customize row ID for unique rows
                            slots={{ toolbar: GridToolbar }} // Add toolbar with export options
                            checkboxSelection

                        />
                    </div>
                </Paper>
            </Box>
        </Layout>
    );
};

export default History;