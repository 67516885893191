// frontend/src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { isAuthenticated, getProfile } from '../services/api'; // Import getProfile to fetch user details

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(isAuthenticated());
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        if (authenticated) {
            fetchUserProfile();
        }
    }, [authenticated]);

    const fetchUserProfile = async () => {
        try {
            const profile = await getProfile();
            setUser(profile.user);
            setPermissions(profile.permissions || []); // Set permissions, fallback to empty array if undefined
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    const login = () => {
        setAuthenticated(true);
        fetchUserProfile();
    };

    const logout = () => {
        setAuthenticated(false);
        setUser(null);
        setPermissions([]);  // Clear permissions on logout
    };

    return (
        <AuthContext.Provider value={{ authenticated, user, permissions, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

