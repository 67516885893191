import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getCourierDispatchSummary } from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';
import moment from 'moment';

const CourierDispatchReport = ({ granularity, startDate, endDate }) => {
    const [dispatchData, setDispatchData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showSnackbar } = useSnackbar();

    const fetchDispatchData = async () => {
        setLoading(true);
        try {
            const data = await getCourierDispatchSummary(granularity, startDate, endDate);
            setDispatchData(data);
            showSnackbar('Courier dispatch data fetched successfully.', 'success');
        } catch (error) {
            showSnackbar('Failed to fetch courier dispatch data.', 'error');
            console.error('Fetch Dispatch Data Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDispatchData();
    }, [granularity, startDate, endDate]);

    // Prepare data for the chart
    const chartData = dispatchData.reduce((acc, dataPoint) => {
        const label = granularity === 'Month'
            ? moment({ year: dataPoint.Year, month: dataPoint.Period - 1 }).format('YYYY MMM')
            : moment(dataPoint.Period).format('YYYY-MM-DD');

        const existingEntry = acc.find(entry => entry.label === label);
        if (existingEntry) {
            existingEntry[dataPoint.Courier] = (existingEntry[dataPoint.Courier] || 0) + dataPoint.OrdersSent;
        } else {
            const newEntry = { label };
            newEntry[dataPoint.Courier] = dataPoint.OrdersSent;
            acc.push(newEntry);
        }
        return acc;
    }, []);

    // Define unique couriers
    const couriers = [...new Set(dispatchData.map(data => data.Courier))];

    // Define colors for each courier
    const colors = ['#197DC2', '#20c997', '#dc3545', '#ffc107', '#6f42c1', '#007bff']; // Add more colors as needed

    return (
        <Box sx={{ mb: 5 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                Courier Dispatch Report
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {couriers.map((courier, index) => (
                                <Bar key={courier} dataKey={courier} fill={colors[index % colors.length]} stackId="a" />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                    <TableContainer component={Paper} sx={{ mt: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Courier</strong></TableCell>
                                    <TableCell align="right"><strong>Period</strong></TableCell>
                                    <TableCell align="right"><strong>Orders Sent</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dispatchData.map(row => (
                                    <TableRow key={`${row.Courier}-${row.Period}`}>
                                        <TableCell component="th" scope="row">{row.Courier}</TableCell>
                                        <TableCell align="right">
                                            {granularity === 'Month'
                                                ? moment({ year: row.Year, month: row.Period - 1 }).format('YYYY MMM')
                                                : granularity === 'Week'
                                                ? `Week Starting ${moment(row.Period).format('YYYY-MM-DD')}`
                                                : moment(row.Period).format('YYYY-MM-DD')}
                                        </TableCell>
                                        <TableCell align="right">{row.OrdersSent}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    );
};

export default CourierDispatchReport;
