// frontend/src/components/BreadcrumbComponent.js
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadcrumbComponent = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ display: 'inline-flex', alignItems: 'center' }}  // Align items center for proper vertical alignment
            >
                <Link sx={{ display: 'flex', alignItems: 'center' }} underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                    Home
                </Link>
                {pathnames.map((value, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;

                    return isLast ? (
                        <Typography sx={{ display: 'flex', alignItems: 'center', mb:1.5 }} color="text.primary" key={value}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                        </Typography>
                    ) : (
                        <Link sx={{ display: 'flex', alignItems: 'center', mb:1.5 }} underline="hover" color="inherit" component={RouterLink} to={routeTo} key={value}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};

export default BreadcrumbComponent;

