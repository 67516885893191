// frontend/src/components/Dashboard.js

import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    LinearProgress,
    Popover,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Link, // Import MUI's Link component
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
} from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import StorageIcon from '@mui/icons-material/Storage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CancelIcon from '@mui/icons-material/Cancel';
import Layout from './Layout';
import { getOrdersSummary, getRequiredDispatchTodayOrders } from '../services/api';
import LoadingIndicator from './LoadingIndicator'; // Ensure this component exists

// Reusable SummaryCard Component
const SummaryCard = ({ icon: Icon, label, value, color, subLabel }) => (
    <Card
        sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: '#fff',
            height: '150px', // Fixed height for consistency
            width: '100%', // Ensure it takes full width of Grid item
        }}
        elevation={3}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 64,
                width: 64,
                backgroundColor: color,
                color: '#fff',
                borderRadius: '50%',
                marginRight: 2,
                flexShrink: 0, // Prevent shrinking
            }}
        >
            <Icon fontSize="large" />
        </Box>
        <Box>
            <Typography variant="body2" color="textSecondary">
                {label}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {value}
            </Typography>
            {subLabel && (
                <Typography variant="caption" color="textSecondary">
                    {subLabel}
                </Typography>
            )}
        </Box>
    </Card>
);

// Reusable ClientCard Component
const ClientCard = ({ client, onPopoverOpen, onDispatchTodayClick }) => {
    // Updated Progress Value Calculation
    const progressValue =
        client.dispatchedOrders === 0 && client.requiredDispatchToday === 0
            ? 100
            : client.progressPercentage || 0;

    // Determine Progress Bar Color
    const progressColor =
        progressValue >= 100
            ? '#20c997' // Green
            : progressValue >= 50
            ? '#fd7e14' // Orange
            : '#dc3545'; // Red

    // Determine Required Dispatch Today Color
    const requiredDispatchColor =
        client.requiredDispatchToday === 0 ? '#20c997' : '#dc3545';

    // Determine Cancelled Orders Color
    const cancelledOrdersColor =
        client.cancelledOrdersToday > 0 ? '#dc3545' : '#20c997';

    return (
        <Grid item xs={12} sm={6} md={4} key={client.clientName}>
            <Card
                sx={{
                    p: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                    position: 'relative',
                    backgroundColor: '#fff',
                    height: '100%', // Make card height consistent
                }}
                elevation={3}
            >
                {/* Top Section: Client Name and Popover Icon */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: '#333' }}
                    >
                        {client.clientName}
                    </Typography>

                    <IconButton
                        size="small"
                        onClick={(e) => onPopoverOpen(e, client)}
                        aria-label={`View cutoff times for ${client.clientName}`}
                    >
                        <AccessAlarmIcon />
                    </IconButton>
                </Box>

                {/* Middle Section: Total New Orders and Required Dispatch Today */}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    {/* Total New Orders */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="textSecondary">
                            Total New Orders (Today)
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 'bold', color: '#197DC2' }}
                        >
                            {client.totalNewOrders || 0}
                        </Typography>
                    </Grid>

                    {/* Required Dispatch Today */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="textSecondary">
                            Required Dispatch Today
                        </Typography>
                        <Typography
                            variant="h3" // Increased font size
                            sx={{ fontWeight: 'bold', color: requiredDispatchColor }}
                        >
                            <Link
                                component="button"
                                variant="h4"
                                onClick={() => onDispatchTodayClick(client.clientName)}
                                underline="hover"
                                color="inherit"
                                sx={{fontWeight: 'bold', cursor: 'pointer' }}
                            >
                                {client.requiredDispatchToday || 0}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>

                {/* Lower Section: Dispatched Orders and Cancelled Orders */}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    {/* Dispatched Orders */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="textSecondary">
                            Dispatched (Today)
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 'bold', color: '#20c997' }}
                        >
                            {client.dispatchedOrders || 0}
                        </Typography>
                    </Grid>

                    {/* Cancelled Orders Today */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="textSecondary">
                            Cancelled Orders Today
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 'bold', color: cancelledOrdersColor }}
                        >
                            {client.cancelledOrdersToday || 0}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Progress Bar */}
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                        Progress
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={progressValue > 100 ? 100 : progressValue}
                        sx={{
                            height: 10,
                            borderRadius: 5,
                            [`& .MuiLinearProgress-bar`]: {
                                backgroundColor: progressColor,
                            },
                        }}
                        aria-valuenow={progressValue}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    />
                    <Typography
                        variant="caption"
                        sx={{ display: 'block', textAlign: 'right', color: '#666', mt: 1 }}
                    >
                        {progressValue}%
                    </Typography>
                </Box>
            </Card>
        </Grid>
    );
};


const Dashboard = () => {
    // State Variables
    const [ordersData, setOrdersData] = useState({ summary: {}, clients: [] });
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverClient, setPopoverClient] = useState(null);
    const [dateTime, setDateTime] = useState('');
    const [intervalTime, setIntervalTime] = useState(15000); // Default 15s interval
    const [error, setError] = useState(null); // For error handling
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedClientName, setSelectedClientName] = useState('');
    const [dispatchOrders, setDispatchOrders] = useState([]);
    const [dispatchLoading, setDispatchLoading] = useState(false);
    const [dispatchError, setDispatchError] = useState(null);

    // Interval Options for Reload
    const intervalOptions = [
        { label: '5 seconds', value: 5000 },
        { label: '15 seconds', value: 15000 },
        { label: '1 minute', value: 60000 },
        { label: '5 minutes', value: 300000 },
        { label: '10 minutes', value: 600000 },
        { label: '30 minutes', value: 1800000 },
        { label: '1 hour', value: 3600000 },
    ];

    // Update Current Date and Time Every Second
    useEffect(() => {
        const updateDateTime = () => {
            const today = new Date();
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            setDateTime(today.toLocaleDateString('en-UK', options));
        };

        updateDateTime();
        const intervalId = setInterval(updateDateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Fetch Orders Data from Backend
    const fetchOrdersData = useCallback(async () => {
        try {
            const response = await getOrdersSummary();
            setOrdersData(response);
            setError(null); // Reset error on successful fetch
            
        } catch (err) {
            console.error('Error fetching orders data:', err);
            setError('Failed to fetch dashboard data. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, []);

    // Initial Data Fetch
    useEffect(() => {
        fetchOrdersData();
    }, [fetchOrdersData]);

    // Manage Reload Based on Selected Interval
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchOrdersData();
        }, intervalTime);

        // Cleanup on Unmount or when intervalTime changes
        return () => clearInterval(intervalId);
    }, [fetchOrdersData, intervalTime]);

    // Handle Popover Open
    const handlePopoverOpen = (event, client) => {
        setAnchorEl(event.currentTarget);
        setPopoverClient(client);
    };

    // Handle Popover Close
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverClient(null);
    };

    const openPopover = Boolean(anchorEl);

    // Format Cutoff Time for Display
    const formatCutoffTime = (time) => {
        if (time) {
            // Append 'Z' to indicate UTC time if not already present
            const utcTime = time.endsWith('Z') ? time : `${time}Z`;
            const date = new Date(utcTime);
            if (!isNaN(date)) {
                return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' });
            }
        }
        return 'N/A';
    };

    // Handle clicking on Required Dispatch Today link
    const handleDispatchTodayClick = async (clientName) => {
        setSelectedClientName(clientName);
        setDispatchLoading(true);
        setDispatchError(null);
        setModalOpen(true);

        try {
            const orders = await getRequiredDispatchTodayOrders(clientName);
            setDispatchOrders(orders);
        } catch (error) {
            setDispatchError('Failed to load orders. Please try again.');
        } finally {
            setDispatchLoading(false);
        }
    };

    // Handle modal close
    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedClientName('');
        setDispatchOrders([]);
        setDispatchError(null);
    };

    // Render Each Client's Statistics Card
    const renderClientCard = (client) => (
        <ClientCard
            key={client.clientName}
            client={client}
            onPopoverOpen={handlePopoverOpen}
            onDispatchTodayClick={handleDispatchTodayClick} // Pass the handler
        />
    );

    // If Data is Loading, Show Loading Indicator
    if (loading) {
        return (
            <Layout>
                <LoadingIndicator />
            </Layout>
        );
    }

    return (
        <Layout>
            <Box sx={{ p: { xs: 2, sm: 3 }, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        alignItems: { xs: 'flex-start', sm: 'center' },
                        mb: 3,
                    }}
                >
                    <Box>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                            Summary
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {dateTime}
                        </Typography>
                    </Box>
                    {/* Reload Interval Selector */}
                    <FormControl variant="outlined" sx={{ minWidth: 150, mt: { xs: 2, sm: 0 } }}>
                        <InputLabel id="reload-interval-label">Reload Interval</InputLabel>
                        <Select
                            labelId="reload-interval-label"
                            id="reload-interval"
                            value={intervalTime}
                            onChange={(e) => setIntervalTime(e.target.value)}
                            label="Reload Interval"
                        >
                            {intervalOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Display Error Message if Exists */}
                {error && (
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="body1" color="error">
                            {error}
                        </Typography>
                    </Box>
                )}

                {/* Summary Cards */}
                <Grid
                    container
                    spacing={2}
                    columns={60} // Set total columns to 60 for better division
                    sx={{ mb: 4 }}
                >
                    <Grid item xs={60} sm={30} md={20} lg={12}>
                        <SummaryCard
                            icon={StorageIcon}
                            label="Total New Orders"
                            value={ordersData.summary.newOrdersTotal || 0}
                            color="#197DC2"
                            subLabel="Today"
                        />
                    </Grid>
                    <Grid item xs={60} sm={30} md={20} lg={12}>
                        <SummaryCard
                            icon={LocalShippingIcon}
                            label="Dispatched (Today)"
                            value={ordersData.summary.dispatched || 0}
                            color="#20c997" // Always green since it's all dispatched today
                            subLabel="Tracked from System"
                        />
                    </Grid>
                    <Grid item xs={60} sm={30} md={20} lg={12}>
                        <SummaryCard
                            icon={AssessmentIcon}
                            label="Progress (%)"
                            value={ordersData.summary.progressPercentage || 0}
                            color={
                                ordersData.summary.progressPercentage >= 100
                                    ? '#20c997' // Green
                                    : ordersData.summary.progressPercentage >= 50
                                    ? '#fd7e14' // Orange
                                    : '#dc3545' // Red
                            }
                            subLabel="Processing Status"
                        />
                    </Grid>
                    <Grid item xs={60} sm={30} md={20} lg={12}>
                        <SummaryCard
                            icon={PriorityHighIcon}
                            label="Required Dispatch Today"
                            value={ordersData.summary.requiredDispatchedToday || 0}
                            color={
                                ordersData.summary.requiredDispatchedToday === 0
                                    ? '#20c997'
                                    : '#dc3545'
                            }
                            subLabel="Dispatch Urgency"
                        />
                    </Grid>
                    <Grid item xs={60} sm={30} md={20} lg={12}>
                        <SummaryCard
                            icon={CancelIcon}
                            label="Cancelled Orders Today"
                            value={ordersData.summary.cancelledOrdersToday || 0}
                            color={
                                ordersData.summary.cancelledOrdersToday > 0
                                    ? '#dc3545'
                                    : '#20c997'
                            }
                            subLabel="Orders Cancelled Today"
                        />
                    </Grid>
                </Grid>

                {/* Clients Statistics */}
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Client's Statistics
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    {ordersData.clients && ordersData.clients.length > 0 ? (
                        ordersData.clients.map(renderClientCard)
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="body1" color="textSecondary">
                                No client data available.
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                {/* Popover for Cutoff Times */}
                <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        elevation: 3,
                        sx: { padding: 2, maxWidth: 300 },
                    }}
                >
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Cutoff Times
                        </Typography>
                        {popoverClient?.cutoffTimes && popoverClient.cutoffTimes.length > 0 ? (
                            popoverClient.cutoffTimes.map((cutoff, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                    <Typography variant="body2">
                                        <strong>{cutoff.shipping_carrier}</strong> -{' '}
                                        {cutoff.shipping_method_condition === 'equals'
                                            ? '=='
                                            : '!='}{' '}
                                        {cutoff.shipping_method}
                                    </Typography>
                                    <Typography variant="body2">
                                        Cutoff Time: {formatCutoffTime(cutoff.cutoff_time)}{' '}
                                        {cutoff.cutoff_day_offset > 0 &&
                                            `(Day Offset: ${cutoff.cutoff_day_offset})`}
                                    </Typography>
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body2">
                                No cutoff times available.
                            </Typography>
                        )}
                    </Box>
                </Popover>

                                {/* Modal for Required Dispatch Today Orders */}
                                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    fullWidth
                    maxWidth="md"
                    aria-labelledby="dispatch-orders-dialog-title"
                >
                    <DialogTitle id="dispatch-orders-dialog-title">
                        Required Dispatch Today Orders for {selectedClientName}
                    </DialogTitle>
                    <DialogContent dividers>
                        {dispatchLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                                <CircularProgress />
                            </Box>
                        ) : dispatchError ? (
                            <Typography variant="body1" color="error">
                                {dispatchError}
                            </Typography>
                        ) : dispatchOrders.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Order Number</TableCell>
                                        <TableCell>Create Date</TableCell>
                                        <TableCell>Shipping Carrier</TableCell>
                                        <TableCell>Shipping Method</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dispatchOrders.map((order) => (
                                        <TableRow key={order.orderId}>
                                            <TableCell>{order.orderNumber}</TableCell>
                                            <TableCell>{new Date(order.createdAt).toLocaleString()}</TableCell>
                                            <TableCell>{order.shippingCarrier}</TableCell>
                                            <TableCell>{order.shippingMethod}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="body1">
                                No required dispatch today orders found for this client.
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );
};

export default Dashboard;
