// frontend/src/components/Sidebar.js
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import { Dashboard, CardGiftcard, History, BarChart, People, ShoppingBag, Diversity3, Assessment } from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';

const drawerWidth = 250;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
    const { permissions } = useContext(AuthContext);  // Get permissions from AuthContext
    const location = useLocation();  // Get current path

    const hasPermission = (permissionName) => {
        return permissions && permissions.includes(permissionName); // Ensure permissions is an array
    };

    // List of menu items
    const menuItems = [
        {
            label: 'Apps - IV Creative',
            isTitle: true,
        },
        {
            label: 'Dashboard',
            icon: <Dashboard />,
            link: '/dashboard',
        },
        {
            label: 'Client\'s Orders',
            icon: <ShoppingBag />,
            link: '/orders',
        },
        hasPermission('ManageClients') && {
            label: 'Clients Management',
            icon: <Diversity3 />,
            link: '/clients',
        },
        hasPermission('ViewReports') && {
            label: 'Reports',
            icon: <Assessment />,
            link: '/reports',
        },
        {
            label: 'Apps - Moonpig',
            isTitle: true,
        },
        {
            label: 'Buy A Gift Report',
            icon: <CardGiftcard />,
            link: '/bagorders',
        },
        {
            label: 'View Orders History',
            icon: <History />,
            link: '/history',
        },
        {
            label: 'Grafana KPI Dashboard',
            icon: <BarChart />,
            link: '/grafanakpi',
        },
        {
            label: 'System Setting',
            isTitle: true,
        },
        hasPermission('ManageUsers') && {
            label: 'Users Management',
            icon: <People />,
            link: '/user-management',
        }
    ].filter(Boolean);  // Filter out `null` values when permissions don't match

    // Drawer content with menu items mapped
    const drawerContent = (
        <>
            <Box sx={{ paddingTop: '15px', textAlign: 'center' }}>
                <a href="/dashboard">
                    <img src="/images/logo.png" alt="Logo" style={{ maxHeight: '40px' }} />
                </a>
            </Box>
            <Divider sx={{ paddingTop: '15px' }} />
            <List>
                {menuItems.map((item, index) => (
                    item.isTitle ? (
                        <ListItem key={index}>
                            <ListItemText
                                primary={item.label}
                                primaryTypographyProps={{
                                    fontSize: 13,
                                    fontWeight: 'medium',
                                    lineHeight: '20px',
                                    mb: '2px',
                                }}
                            />
                        </ListItem>
                    ) : (
                        <ListItemButton key={index} component="a" href={item.link} selected={location.pathname === item.link}>
                            <ListItemIcon sx={{ color: location.pathname === item.link ? 'primary.main' : 'inherit' }} >{item.icon}</ListItemIcon>
                            <ListItemText
                                primary={item.label}
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: 'medium',
                                    color: location.pathname === item.link ? 'primary' : 'inherit',
                                }}
                            />
                        </ListItemButton>
                    )
                ))}
            </List>
            <Divider />
            <Box
                sx={{
                    padding: '16px',
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                }}
            >
                <Button
                    component="a"
                    href="https://dpsdigital.sharepoint.com/sites/DPSHelpdesk/Lists/Tickets/AllItems.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="primary"
                    sx={{
                        textDecoration: 'none',
                        color: '#fff',
                        padding: '8px 20px',
                        borderRadius: '20px',
                        // Optional: Add hover effects
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                            color: '#fff'
                        },
                    }}
                >
                    Support
                </Button>
            </Box>
        </>
    );

    return (
        <>
            <Drawer
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    zIndex: 1300,
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {drawerContent}
            </Drawer>

            <Drawer
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    width: drawerWidth,
                    flexShrink: 0,
                    zIndex: 1300,
                    '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
                }}
                variant="permanent"
                anchor="left"
            >
                {drawerContent}
            </Drawer>
        </>
    );
};

export default Sidebar;
