// frontend/src/components/Clients.js
import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { Edit, Delete, Add, Close, ExpandMore } from '@mui/icons-material';
import {
    getClients,
    addClient,
    editClient,
    deleteClient,
    addCutoffTime,
    editCutoffTime,
    deleteCutoffTime,
} from '../services/api';
import Layout from './Layout';
import { styled } from '@mui/material/styles';
import { useSnackbar } from '../contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';

const Clients = () => {
    const { showSnackbar } = useSnackbar();
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [cutoffDialogOpen, setCutoffDialogOpen] = useState(false);
    const [cutoffConfirmDialogOpen, setCutoffConfirmDialogOpen] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
    const [cutoffToDelete, setCutoffToDelete] = useState(null);
    const [editClientId, setEditClientId] = useState(null);
    const [newClient, setNewClient] = useState({ clientName: '', fulfilmentChannel: '' });
    const [cutoff, setCutoff] = useState({
        clientId: '',
        shipping_carrier: '',
        shipping_method_condition: 'equals',
        shipping_method: '',
        cutoff_time: '',
        cutoff_day_offset: 0,
    });
    const [editCutoffId, setEditCutoffId] = useState(null);
    const [cutoffClient, setCutoffClient] = useState(null);
    const [expanded, setExpanded] = useState(false);

    // Predefined list of shipping carriers
    const carriers = ['Royal Mail', 'Evri', 'Hermes', 'Parcelforce', 'DPD', 'DHL', 'Fedex', 'UPS'];

    const fetchClients = async () => {
        try {
            const response = await getClients();
            setClients(response);
        } catch (error) {
            if (error.status === 403) {
                showSnackbar('You do not have permission to access this resource', 'error');
                navigate('/access-denied'); // Redirect to Access Denied page
            } else {
                showSnackbar('Failed to load clients.', 'error');
            }
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const handleAddClient = async () => {
        try {
            if (editClientId) {
                await editClient(editClientId, newClient);
                showSnackbar('Client updated successfully.', 'success');
            } else {
                await addClient(newClient);
                showSnackbar('Client added successfully.', 'success');
            }
            fetchClients();
            handleCloseDialog();
        } catch (error) {
            showSnackbar('Failed to add client.', 'error');
        }
    };

    const handleDeleteClient = async () => {
        try {
            await deleteClient(clientToDelete);
            fetchClients();
            showSnackbar('Client deleted successfully.', 'success');
            handleCloseConfirmDialog();
        } catch (error) {
            showSnackbar('Failed to delete client.', 'error');
        }
    };

    const handleAddCutoffTime = async () => {
        try {
            if (editCutoffId) {
                await editCutoffTime(editCutoffId, cutoffClient.clientId, cutoff);
                showSnackbar('Cutoff time updated successfully.', 'success');
            } else {
                await addCutoffTime(cutoffClient.clientId, cutoff);
                showSnackbar('Cutoff time added successfully.', 'success');
            }
            fetchClients();
            handleCloseCutoffDialog();
        } catch (error) {
            showSnackbar('Failed to add/edit cutoff time.', 'error');
        }
    };

    const handleDeleteCutoffTime = async () => {
        try {
            await deleteCutoffTime(cutoffToDelete);
            fetchClients();
            showSnackbar('Cutoff time deleted successfully.', 'success');
            handleCloseCutoffConfirmDialog();
        } catch (error) {
            showSnackbar('Failed to delete cutoff time.', 'error');
        }
    };

    const handleOpenDialog = (client = null) => {
        if (client) {
            setEditClientId(client.clientId);
            setNewClient({ clientName: client.clientName, fulfilmentChannel: client.fulfilmentChannel });
        } else {
            setEditClientId(null);
            setNewClient({ clientName: '', fulfilmentChannel: '' });
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewClient({ clientName: '', fulfilmentChannel: '' });
    };

    const handleOpenConfirmDialog = (clientId) => {
        setClientToDelete(clientId);
        setConfirmDialogOpen(true);
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
        setClientToDelete(null);
    };

    const handleOpenCutoffDialog = (client, cutoff = null) => {
        setCutoffClient(client);
        if (cutoff) {
            setCutoff({
                clientId: client.clientId,
                shipping_carrier: cutoff.shipping_carrier,
                shipping_method: cutoff.shipping_method,
                shipping_method_condition: cutoff.shipping_method_condition || 'equals',
                cutoff_time: formatTime(cutoff.cutoff_time),
                cutoff_day_offset: cutoff.cutoff_day_offset,
            });
            setEditCutoffId(cutoff.cutoffId);
        } else {
            setCutoff({
                clientId: client.clientId,
                shipping_carrier: '',
                shipping_method_condition: 'equals',
                shipping_method: '',
                cutoff_time: '',
                cutoff_day_offset: 0,
            });
            setEditCutoffId(null);
        }
        setCutoffDialogOpen(true);
    };

    const handleCloseCutoffDialog = () => {
        setCutoffDialogOpen(false);
        setCutoff({
            clientId: '',
            shipping_carrier: '',
            shipping_method: '',
            shipping_method_condition: 'equals',
            cutoff_time: '',
            cutoff_day_offset: 0,
        });
        setEditCutoffId(null);
    };

    const handleOpenCutoffConfirmDialog = (cutoffId) => {
        setCutoffToDelete(cutoffId);
        setCutoffConfirmDialogOpen(true);
    };

    const handleCloseCutoffConfirmDialog = () => {
        setCutoffConfirmDialogOpen(false);
        setCutoffToDelete(null);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const formatTime = (timeString) => {
        if (timeString && timeString.includes('T')) {
            const timePart = timeString.split('T')[1];
            const [hours, minutes] = timePart.split(':');
            return `${hours}:${minutes}`;
        }
        return timeString; // Return as is if already formatted
    };

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Clients Management
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => handleOpenDialog()}
                    sx={{ mb: 2 }}
                >
                    Add New Client
                </Button>

                {clients.map((client) => (
                    <Accordion
                        key={client.clientId}
                        expanded={expanded === client.clientId}
                        onChange={handleChange(client.clientId)}
                        sx={{ mb: 2 }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={`panel${client.clientId}-content`}
                            id={`panel${client.clientId}-header`}
                        >
                            <Typography variant="h6">{client.clientName}</Typography>
                            <Box sx={{ marginLeft: 'auto' }}>
                                <IconButton color="primary" onClick={() => handleOpenDialog(client)}>
                                    <Edit />
                                </IconButton>
                                <IconButton color="secondary" onClick={() => handleOpenConfirmDialog(client.clientId)}>
                                    <Delete />
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<Add />}
                                onClick={() => handleOpenCutoffDialog(client)}
                                sx={{ mb: 2 }}
                            >
                                Add Cutoff Time
                            </Button>

                            {client.cutoffTimes && client.cutoffTimes.length > 0 ? (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Shipping Carrier</TableCell>
                                                <TableCell>Condition</TableCell>
                                                <TableCell>Shipping Method</TableCell>
                                                <TableCell>Cutoff Time</TableCell>
                                                <TableCell>Day Offset</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {client.cutoffTimes.map((cutoff) => (
                                                <TableRow key={cutoff.cutoffId}>
                                                    <TableCell>{cutoff.shipping_carrier}</TableCell>
                                                    <TableCell>{cutoff.shipping_method_condition}</TableCell>
                                                    <TableCell>{cutoff.shipping_method}</TableCell>
                                                    <TableCell>{formatTime(cutoff.cutoff_time)}</TableCell>
                                                    <TableCell>{cutoff.cutoff_day_offset}</TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => handleOpenCutoffDialog(client, cutoff)}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={() => handleOpenCutoffConfirmDialog(cutoff.cutoffId)}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography sx={{ padding: 3 }}>No cutoff times available.</Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}

                {/* Add and Edit Client Dialog */}
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>{editClientId ? 'Edit Client' : 'Add New Client'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="Client Name"
                            type="text"
                            fullWidth
                            value={newClient.clientName}
                            onChange={(e) => setNewClient({ ...newClient, clientName: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="Fulfilment Channel"
                            type="text"
                            fullWidth
                            value={newClient.fulfilmentChannel}
                            onChange={(e) => setNewClient({ ...newClient, fulfilmentChannel: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="secondary" startIcon={<Close />}>
                            Cancel
                        </Button>
                        <Button onClick={handleAddClient} color="primary" startIcon={<Add />}>
                            {editClientId ? 'Update Client' : 'Add Client'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add and Edit Cutoff Time Dialog */}
                <Dialog open={cutoffDialogOpen} onClose={handleCloseCutoffDialog}>
                    <DialogTitle>
                        {editCutoffId ? 'Edit Cutoff Time' : 'Add Cutoff Time'} for {cutoffClient?.clientName}
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Shipping Carrier</InputLabel>
                            <Select
                                value={cutoff.shipping_carrier}
                                onChange={(e) => setCutoff({ ...cutoff, shipping_carrier: e.target.value })}
                            >
                                {carriers.map((carrier) => (
                                    <MenuItem key={carrier} value={carrier}>
                                        {carrier}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Condition</InputLabel>
                            <Select
                                value={cutoff.shipping_method_condition}
                                onChange={(e) => setCutoff({ ...cutoff, shipping_method_condition: e.target.value })}
                            >
                                <MenuItem value="equals">Equals</MenuItem>
                                <MenuItem value="not equals">Not Equals</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Shipping Method"
                            type="text"
                            fullWidth
                            value={cutoff.shipping_method}
                            onChange={(e) => setCutoff({ ...cutoff, shipping_method: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="Cutoff Time"
                            type="time"
                            fullWidth
                            value={cutoff.cutoff_time}
                            onChange={(e) => setCutoff({ ...cutoff, cutoff_time: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="Day Offset"
                            type="number"
                            fullWidth
                            value={cutoff.cutoff_day_offset}
                            onChange={(e) =>
                                setCutoff({ ...cutoff, cutoff_day_offset: parseInt(e.target.value) || 0 })
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCutoffDialog} color="secondary" startIcon={<Close />}>
                            Cancel
                        </Button>
                        <Button onClick={handleAddCutoffTime} color="primary" startIcon={<Add />}>
                            {editCutoffId ? 'Update Cutoff Time' : 'Add Cutoff Time'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Client Confirmation Dialog */}
                <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
                    <DialogTitle>Delete Client</DialogTitle>
                    <DialogContent>Are you sure you want to delete this client?</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirmDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteClient} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Cutoff Confirmation Dialog */}
                <Dialog open={cutoffConfirmDialogOpen} onClose={handleCloseCutoffConfirmDialog}>
                    <DialogTitle>Delete Cutoff Time</DialogTitle>
                    <DialogContent>Are you sure you want to delete this cutoff time?</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCutoffConfirmDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteCutoffTime} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );
};

export default Clients;
