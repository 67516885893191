// frontend/src/components/UserManagement.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Grid,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Paper,
    Toolbar,
    Tooltip,
    TablePagination,
    TableSortLabel,
    Box,
} from '@mui/material';
import { Delete, Edit, Add, Key, FilterList as FilterListIcon } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import Layout from './Layout';
import { getUsers, addUser, editUser, deleteUser, resetUserPassword } from '../services/api';
import '../styles/UserManagement.css';
import { validatePassword } from '../utils/validation';
import { useSnackbar } from '../contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';

const UserManagement = () => {
    const { showSnackbar } = useSnackbar();
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const loadUsers = async () => {
        try {
            const response = await getUsers();
            setUsers(response && response.users ? response.users : []);
        } catch (error) {
            if (error.status === 403) {
                showSnackbar('You do not have permission to access this resource', 'error');
                navigate('/access-denied');  // Redirect to Access Denied page
            } else {
                showSnackbar('Error fetching users.', 'error');
            }
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const [newUser, setNewUser] = useState({
        username: '',
        password: '',
        RoleId: '2',
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '',
        phoneNumber: '',
        jobTitle: '',
        department: '',
    });
    const [editUserModal, setEditUserModal] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('username');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dense] = useState(true);
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedUsers = users.sort((a, b) => {
        if (orderBy === 'username') {
            return order === 'asc' ? a.Username.localeCompare(b.Username) : b.Username.localeCompare(a.Username);
        } else if (orderBy === 'firstName') {
            return order === 'asc' ? a.FirstName.localeCompare(b.FirstName) : b.FirstName.localeCompare(a.FirstName);
        } else if (orderBy === 'lastName') {
            return order === 'asc' ? a.LastName.localeCompare(b.LastName) : b.LastName.localeCompare(a.LastName);
        } else if (orderBy === 'email') {
            return order === 'asc' ? a.Email.localeCompare(b.Email) : b.Email.localeCompare(a.Email);
        } else if (orderBy === 'RoleId') {
            return order === 'asc' ? a.RoleId - b.RoleId : b.RoleId - a.RoleId;
        }
        return 0;
    });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = users.map((n) => n.Id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleAddUser = async () => {
        if (!validatePassword(newUser.password)) {
            showSnackbar('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.', 'error');
            return;
        }

        try {
            await addUser(newUser);
            showSnackbar(`User ${newUser.username} has been created successfully.`, 'success');
            setShowAddModal(false);
            setNewUser({
                username: '',
                password: '',
                RoleId: '2',
                firstName: '',
                lastName: '',
                email: '',
                countryCode: '',
                phoneNumber: '',
                jobTitle: '',
                department: '',
            });
            loadUsers();
        } catch (error) {
            showSnackbar('Error adding user', 'error');
        }
    };

    const handleEditUser = async () => {
        if (editUserModal.password && !validatePassword(editUserModal.password)) {
            showSnackbar('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.', 'error');
            return;
        }

        try {
            await editUser(editUserModal.Id, {
                username: editUserModal.Username,
                RoleId: editUserModal.RoleId,
                firstName: editUserModal.FirstName,
                lastName: editUserModal.LastName,
                email: editUserModal.Email,
                countryCode: editUserModal.CountryCode,
                phoneNumber: editUserModal.PhoneNumber,
                jobTitle: editUserModal.JobTitle,
                department: editUserModal.Department,
            });
            showSnackbar('User has been updated successfully.', 'success');
            setEditUserModal(null);
            loadUsers();
        } catch (error) {
            showSnackbar('Error editing user', 'error');
        }
    };

    const handleDeleteUser = async () => {
        try {
            await deleteUser(selected);
            showSnackbar('Selected user(s) have been deleted successfully.', 'success');
            setSelected([]);
            setShowDeleteModal(false);
            loadUsers();
        } catch (error) {
            showSnackbar('Error deleting user', 'error');
        }
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        if (!validatePassword(password)) {
            setPasswordError('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.');
        } else {
            setPasswordError('');
        }
        setNewUser({ ...newUser, password });
    };

    const handleResetPasswordChange = (e) => {
        const password = e.target.value;
        if (!validatePassword(password)) {
            setResetPasswordError('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.');
        } else {
            setResetPasswordError('');
        }
        setResetPasswordModal({ ...resetPasswordModal, newPassword: password });
    };

    const handleResetPassword = async () => {
        if (!validatePassword(resetPasswordModal.newPassword)) {
            showSnackbar('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.', 'error');
            return;
        }

        try {
            await resetUserPassword(resetPasswordModal.Id, resetPasswordModal.newPassword);
            showSnackbar('Password has been reset successfully.', 'success');
            setResetPasswordModal(null);
            loadUsers();
        } catch (error) {
            showSnackbar('Error resetting password', 'error');
        }
    };

    return (
        <Layout>

            <Box sx={{ padding: 3 }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>User Management</Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => setShowAddModal(true)}
                    style={{ marginBottom: '20px' }}
                >
                    Add User
                </Button>

                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <Toolbar
                            sx={[
                                {
                                    pl: { sm: 2 },
                                    pr: { xs: 1, sm: 1 },
                                },
                                selected.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                },
                            ]}
                        >
                            {selected.length > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {selected.length} selected
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div"
                                >
                                    Users
                                </Typography>
                            )}

                            {selected.length > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton onClick={() => setShowDeleteModal(true)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Filter list">
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Toolbar>

                        <TableContainer>
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={selected.length > 0 && selected.length < users.length}
                                                checked={users.length > 0 && selected.length === users.length}
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label': 'select all users',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            key="Username"
                                            sortDirection={orderBy === 'username' ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === 'username'}
                                                direction={orderBy === 'username' ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, 'username')}
                                            >
                                                Username
                                                {orderBy === 'username' ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            key="firstName"
                                            sortDirection={orderBy === 'firstName' ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === 'firstName'}
                                                direction={orderBy === 'firstName' ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, 'firstName')}
                                            >
                                                First Name
                                                {orderBy === 'firstName' ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            key="lastName"
                                            sortDirection={orderBy === 'lastName' ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === 'lastName'}
                                                direction={orderBy === 'lastName' ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, 'lastName')}
                                            >
                                                Last Name
                                                {orderBy === 'lastName' ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            key="email"
                                            sortDirection={orderBy === 'email' ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === 'email'}
                                                direction={orderBy === 'email' ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, 'email')}
                                            >
                                                Email
                                                {orderBy === 'email' ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            key="RoleId"
                                            sortDirection={orderBy === 'RoleId' ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === 'RoleId'}
                                                direction={orderBy === 'RoleId' ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, 'RoleId')}
                                            >
                                                RoleId
                                                {orderBy === 'RoleId' ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => {
                                        const isItemSelected = isSelected(user.Id);
                                        const labelId = `enhanced-table-checkbox-${user.Id}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, user.Id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={user.Id}
                                                selected={isItemSelected}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                                    {user.Username}
                                                </TableCell>
                                                <TableCell>{user.FirstName}</TableCell>
                                                <TableCell>{user.LastName}</TableCell>
                                                <TableCell>{user.Email}</TableCell>
                                                <TableCell>{user.RoleId === 1 ? 'Admin' : 'User'}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="Edit User">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => setEditUserModal(user)}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Reset Password">
                                                    <IconButton
                                                        color="warning"
                                                        onClick={() =>
                                                            setResetPasswordModal({ Id: user.Id, newPassword: '' })
                                                        }
                                                    >
                                                        <Key />
                                                    </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>

                {/* Add User Modal */}
                <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="md">
                    <DialogTitle>Add New User</DialogTitle>
                    <DialogContent className="add-user-dialog-content">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="username-add"
                                    fullWidth
                                    label="Username"
                                    value={newUser.username}
                                    onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="password-add"
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    value={newUser.password}
                                    onChange={handlePasswordChange}
                                    required
                                    error={!!passwordError}
                                    helperText={passwordError}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="role-add"
                                    fullWidth
                                    select
                                    label="RoleId"
                                    value={newUser.RoleId}
                                    onChange={(e) => setNewUser({ ...newUser, RoleId: e.target.value })}
                                    required
                                >
                                    <MenuItem value="1">Admin</MenuItem>
                                    <MenuItem value="2">User</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="first-name-add"
                                    fullWidth
                                    label="First Name"
                                    value={newUser.firstName}
                                    onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="last-name-add"
                                    fullWidth
                                    label="Last Name"
                                    value={newUser.lastName}
                                    onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="email-add"
                                    fullWidth
                                    label="Email"
                                    type="email"
                                    value={newUser.email}
                                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="country-code-add"
                                    fullWidth
                                    label="Country Code"
                                    value={newUser.countryCode}
                                    onChange={(e) => setNewUser({ ...newUser, countryCode: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="phone-number-add"
                                    fullWidth
                                    label="Phone Number"
                                    value={newUser.phoneNumber}
                                    onChange={(e) => setNewUser({ ...newUser, phoneNumber: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="job-title-add"
                                    fullWidth
                                    label="Job Title"
                                    value={newUser.jobTitle}
                                    onChange={(e) => setNewUser({ ...newUser, jobTitle: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="department-add"
                                    fullWidth
                                    label="Department"
                                    value={newUser.department}
                                    onChange={(e) => setNewUser({ ...newUser, department: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowAddModal(false)} color="secondary">Cancel</Button>
                        <Button onClick={handleAddUser} color="primary">Save User</Button>
                    </DialogActions>
                </Dialog>

                {/* Edit User Modal */}
                {editUserModal && (
                    <Dialog open={Boolean(editUserModal)} onClose={() => setEditUserModal(null)} fullWidth maxWidth="md">
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent className="edit-user-dialog-content">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="username-edit"
                                        fullWidth
                                        label="Username"
                                        value={editUserModal.Username || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="role-edit"
                                        fullWidth
                                        select
                                        label="RoleId"
                                        value={editUserModal.RoleId}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, RoleId: e.target.value })}
                                        required
                                    >
                                        <MenuItem value="1">Admin</MenuItem>
                                        <MenuItem value="2">User</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="first-name-edit"
                                        fullWidth
                                        label="First Name"
                                        value={editUserModal.FirstName || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, FirstName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="last-name-edit"
                                        fullWidth
                                        label="Last Name"
                                        value={editUserModal.LastName || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, LastName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="email-edit"
                                        fullWidth
                                        label="Email"
                                        type="email"
                                        value={editUserModal.Email || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, Email: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="country-code-edit"
                                        fullWidth
                                        label="Country Code"
                                        value={editUserModal.CountryCode || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, CountryCode: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="phone-number-edit"
                                        fullWidth
                                        label="Phone Number"
                                        value={editUserModal.PhoneNumber || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, PhoneNumber: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="job-title-edit"
                                        fullWidth
                                        label="Job Title"
                                        value={editUserModal.JobTitle || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, JobTitle: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="department-edit"
                                        fullWidth
                                        label="Department"
                                        value={editUserModal.Department || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, Department: e.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditUserModal(null)} color="secondary">Cancel</Button>
                            <Button onClick={handleEditUser} color="primary">Save Changes</Button>
                        </DialogActions>
                    </Dialog>
                )}

                {/* Delete Confirmation Modal */}
                <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)} fullWidth maxWidth="xs">
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Do you really want to delete the selected user(s)? This process cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowDeleteModal(false)} color="secondary">Cancel</Button>
                        <Button onClick={handleDeleteUser} color="primary">Delete</Button>
                    </DialogActions>
                </Dialog>

                {/* Reset Password Modal */}
                {resetPasswordModal && (
                    <Dialog open={Boolean(resetPasswordModal)} onClose={() => setResetPasswordModal(null)} fullWidth maxWidth="sm">
                        <DialogTitle>Reset Password</DialogTitle>
                        <DialogContent>
                            <TextField
                                id="new-password"
                                fullWidth
                                label="New Password"
                                type="password"
                                value={resetPasswordModal.newPassword || ''}
                                onChange={handleResetPasswordChange}
                                error={!!resetPasswordError}
                                helperText={resetPasswordError}
                                required
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setResetPasswordModal(null)} color="secondary">Cancel</Button>
                            <Button onClick={handleResetPassword} color="primary">Reset Password</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>
        </Layout>
    );
};

export default UserManagement;
