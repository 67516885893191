import React from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ClientSummary = ({ perClientData, selectedClient, setSelectedClient, clients, granularity }) => {
    return (
        <Box sx={{ mb: 5 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                Per-Client Orders Summary
            </Typography>
            {/* Select Client Dropdown */}
            <Box sx={{ mb: 3 }}>
                <FormControl fullWidth>
                    <InputLabel id="client-select-label">Select Client</InputLabel>
                    <Select
                        labelId="client-select-label"
                        id="client-select"
                        value={selectedClient}
                        label="Select Client"
                        onChange={(e) => setSelectedClient(e.target.value)}
                    >
                        <MenuItem value="All">All Clients</MenuItem>
                        {clients.map((client) => (
                            <MenuItem key={client.clientName} value={client.clientName}>
                                {client.clientName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {perClientData.length > 0 ? (
                <Grid container spacing={3}>
                    {perClientData.map(client => (
                        <Grid item xs={12} sm={6} md={4} key={client.clientName}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography variant="h6" gutterBottom>
                                        {client.clientName}
                                    </Typography>
                                    <Box sx={{ width: '100%', height: 200 }}>
                                        <ResponsiveContainer>
                                            <BarChart
                                                data={client.monthlyData}
                                                margin={{
                                                    top: 20, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="label" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                {/* Stacked Bars */}
                                                <Bar dataKey="DispatchedOrders" stackId="a" fill="#20c997" />
                                                <Bar dataKey="CancelledOrders" stackId="a" fill="#dc3545" />
                                                <Bar dataKey="RemainingInProduction" stackId="a" fill="#197DC2" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Box>
                                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Period</TableCell>
                                                    <TableCell align="right">Received Orders</TableCell>
                                                    <TableCell align="right">Dispatched Orders</TableCell>
                                                    <TableCell align="right">Cancelled Orders</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {client.monthlyData.map((row) => (
                                                    <TableRow key={row.label}>
                                                        <TableCell component="th" scope="row">
                                                            {row.label}
                                                        </TableCell>
                                                        <TableCell align="right">{row.ReceivedOrders}</TableCell>
                                                        <TableCell align="right">{row.DispatchedOrders}</TableCell>
                                                        <TableCell align="right">{row.CancelledOrders}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="body1" color="textSecondary">
                    No per-client data available for the selected year and client.
                </Typography>
            )}
        </Box>
    );
};

export default ClientSummary;
